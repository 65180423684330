import { h } from "preact";
import { Route, Router } from "preact-router";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import { Credits } from "./credits";
import Header from "./Header";
import MenuItem from "./MenuItem";
import Menu from "./Menu";
import Logo from "./Logo";
import Page from "./Page";
import Timelapse from "../routes/timelapse";
import About from "../routes/about";
import Flash from "./Flash";
import ChartsPage from "../routes/charts";
import TestPage from "../routes/test";

const App = () => (
  <div id="app">
    <main>
      <Header>
        <Logo />
        <Flash />
      </Header>
      <Menu>
        <MenuItem href="/">Webcams</MenuItem>
        {/* <MenuItem href="/timelapse">Timelapse</MenuItem> */}
        <MenuItem href="/charts">Weather</MenuItem>
        <MenuItem href="/about">About Us</MenuItem>
      </Menu>
      <Page>
        <Router>
          <Route path="/" component={Home} />
          <Route path="/timelapse" component={Timelapse} />
          <Route path="/timelapse/:code" component={Timelapse} />
          <Route path="/charts" component={ChartsPage} />
          <Route path="/about" component={About} />
          <Route path="/test" component={TestPage} />
        </Router>
      </Page>
      <Credits />
    </main>
  </div>
);

export default App;
